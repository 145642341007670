import axios from "axios";
import API from "@/composables/API";
const apiBaseURL = process.env.VUE_APP_API_PATH;
import { defineStore } from "pinia";
import useToast from "@/global_components/Toast/useToast";
// import { computed } from "@vue/reactivity";
// const report = computed(() => {
//     return JSON.parse(localStorage.getItem('report'))
// })
const setJustifications = (compliance_audited) => {
    let justifications = []
    compliance_audited.forEach((compliance) => {
        if (compliance.justification) {
            justifications.push({
                compliance_audited: compliance.id,
                content: compliance.justification,
                hasNewContent: false,
                answer_justification_at: compliance.answer_justification_at,
            })
        }
    })
    return justifications
}
export const useAuditStore = defineStore("audit", {
    state: () => ({
        auditList: [],
        auditReport: JSON.parse(localStorage.getItem("report")),
        complianceList: [],
        comAuditList: [],
        existingAuditRFQ: [],
        rfqComplianceClassifications: [],
        rfqHasComplianceClassifications: false,
        auditRFQ: [],
        qoDetails: [],
        poDetails: [],
        auditControlsList: [],
        auditPaymentList: [],
        auditDetailList: [],
        auditMessages: [],
        auditCertificatesName: [],
        auditDocuments: [],
        auditSingleCertificate: [],
        supDocumentStatus: [],
        regVerifyCertificates: [],
        loader: false,
        btnLoader: false,
        controlLoader: false,
        poBtnLoader: false,
        rfqViewLoader: false,
        comp_loader: false,
        quotationLoader: false,
        poLoader: false,
        paymentLoader: false,
        sPBLoader: false,
        singleCertificateLoader: false,
        totalPages: 0,
        currentPage: 0,
        Certificate: {},
        auditRptList: [],
        NotauditList: [],
        inProcessAudit: [],
        reAudit: [],
        auditStartWork: false,
        justifications: [],
        auditEventsList: [],
    }),
    actions: {
        async loadAuditList(data) {
            this.loader = true;
            const response = await API.get("api/audit/audit-list", data);
            this.loader = false;
            if (response.status == 200) {
                this.auditList = [];
                this.totalPages = Math.ceil(response.data.TotalRows / data.RowNo);
                let arr = response.data.Pagination;
                const uniqueSet = new Set();
                arr.forEach((obj) => {
                    // Using JSON.stringify to convert the object to a string representation
                    // This ensures unique references for each object in the Set
                    if (!uniqueSet.has(obj.Aud_RFQ_code)) {
                        uniqueSet.add(obj.Aud_RFQ_code);
                        this.auditList.push(obj);
                    }
                });
            } else {
                this.auditList = [];
                useToast(response);
            }
            return response;
        },
        async loadAuditEventsList(data) {
            this.loader = true;
            const response = await API.get(`api/audit/audit-events/${data.id}`);
            this.loader = false;
            if (response.status == 200) {
                this.auditEventsList = response.data.data;
            } else {
                this.auditEventsList = [];
                useToast(response);
            }
            return response;
        },
        // async loadAuditSummary(data) {
        //   this.loader = true;
        //   const response = await API.get("api/general/orgnaization-audit-summary", data);
        //   this.loader = false;
        //   if (response.status == 200) {
        //     this.auditList = [];
        //     this.totalPages = Math.ceil(response.data.TotalRows / data.RowNo);
        //     // this.auditList = response.data.Orgnaization_Audit_Summary;
        //     let arr = response.data.Orgnaization_Audit_Summary;
        //     const uniqueSet = new Set();
        //     arr.forEach((obj) => {
        //       // Using JSON.stringify to convert the object to a string representation
        //       // This ensures unique references for each object in the Set
        //       if (!uniqueSet.has(obj.Aud_RFQ_code)) {
        //         uniqueSet.add(obj.Aud_RFQ_code);
        //         this.auditList.push(obj);
        //       }
        //     });
        //   } else {
        //     this.auditList = [];
        //     useToast(response);
        //   }
        //   return response;
        // },
        async loadAuditAndComplianceList(data) {
            this.comp_loader = true;

            const response = await API.get(`api/projectbid/get-rfq-compliance`, data);

            this.comp_loader = false;

            if (response.status == 200) {
                this.existingAuditRFQ = response.data.Audit_RFQ;
                this.complianceList = response.data.RFQ_compliance;
                this.comAuditList = response.data.Compliance_Aud;
                this.rfqHasComplianceClassifications = response.data.has_classifications === true;
                this.rfqComplianceClassifications = (response.data.classifications ?? []).map(
                    (item) => item.trim()
                );
            } else {
                useToast(response);
            }
        },
        async loadAuditDetailList(data) {
            this.loader = true;
            const response = await API.get(`api/audit/audit-detail-list`, data);
            this.loader = false;
            if (response.status == 200) {
                this.totalPages = Math.ceil(response.data.TotalRows / data.RowNo);
                this.auditDetailList =
                    response.data.Pagination.filter((val) => val.Qutation_status == 1)
                        .length == 0
                        ? response.data.Pagination
                        : response.data.Pagination.filter(
                            (val) => val.Qutation_status == 1
                        );
            } else {
                useToast(response);
            }
        },
        async sendAuditRFQ(data) {
            const response = await API.formData(`api/audit/send-rfq`, data);
            if (response.status == 200) {
                useToast("Audit RFQ send", "success");
            } else useToast(response);
            return response;
        },
        async confirmUploadingAllEvidence(aud_req_id) {
            this.controlLoader = true;
            const response = await API.get(`api/audit/evidences/confirm-upload-all/${aud_req_id}`);
            this.controlLoader = false;
            if (response.status == 200) {
                useToast("Confirm Successfully", "success");
            } else {
                useToast(response)
            }
            return response;
        },
        async sendAuditQuotation(data) {
            this.btnLoader = true;
            const response = await API.formData(`api/audit/send-qutation`, data);
            this.btnLoader = false;
            if (response.status == 200) {
                useToast("Audit Quotation send", "success");
            } else useToast(response);
            return response;
        },
        async sendAuditPurchaseOrder(data) {
            this.poBtnLoader = true;
            const response = await API.formData(`api/audit/send-po`, data);
            this.poBtnLoader = false;
            if (response.status == 200) {
                useToast("Audit PO send", "success");
            } else useToast(response);
            return response;
        },
        async confirmStartAuditing(aud_req_id) {
            this.controlLoader = true;
            const response = await API.get(`api/audit/audit-firm/confirm-start-auditing/${aud_req_id}`);
            this.controlLoader = false;
            if (response.status == 200) {
                useToast("Confirm Successfully", "success");
            } else {
                useToast(response)
            }
            return response;
        },
        async loadAuditRFQ(rfq_id) {
            if (this.rfqViewLoader) {
                return;
            }

            this.rfqViewLoader = true;
            const response = await API.get(`api/audit/view-rfq?Aud_RFQ_id=${rfq_id}`);

            this.rfqViewLoader = false;

            if (response.status == 200) {
                this.auditRFQ = response.data;
            } else {
                this.auditRFQ = [];
                useToast(response?.data);
            }

            return response;
        },
        async loadAuditQuotation(qo_id) {
            this.quotationLoader = true;
            const response = await API.get(
                `api/audit/view-qutation?aud_qutation_id=${qo_id}`
            );

            this.quotationLoader = false;
            if (response.status == 200) {
                this.qoDetails = response.data;
            } else (this.qoDetails = []), useToast(response);
            return response;
        },
        async loadAuditPO(po_id) {
            this.poLoader = true;
            const response = await API.get(`api/audit/view-po?aud_PO_id=${po_id}`);
            this.poLoader = false;
            if (response.status == 200) {
                this.poDetails = response.data;
            } else (this.poDetails = []), useToast(response);
            return response;
        },
        async changeAuditRFQStatus(data) {
            const response = await API.post(`api/audit/change-RFQ-status`, data);
            if (response.status == 200) {
                useToast("RFQ Status Changed", "success");
            } else useToast(response);
        },
        async changeAuditQuotationStatus(data) {
            const response = await API.post(`api/audit/change-qut-status`, data);
            if (response.status == 200) {
                useToast("Quotation Status Changed", "success");
            } else useToast(response);
        },
        async changeAuditPOStatus(data) {
            const response = await API.post(`api/audit/change-po-status`, data);
            if (response.status == 200) {
                useToast("PO Status Changed", "success");
            } else useToast(response);
        },
        async changeSupplyStatus(data) {
            const response = await API.post(`api/audit/supply-status`, data);
            if (response.status == 200) {
                useToast("Supply Status Changed", "success");
            } else useToast(response);
        },
        async sendAuditPayment(data, p_t = "bank") {
            this.sPBLoader = true;
            const response = await API.formData("api/projectbid/send-payment", data);
            this.sPBLoader = false;
            if (response.status == 200) {
                if (p_t == "bank") useToast("Payment successfully sent.", "success");
            } else {
                useToast(response);
            }
            return response;
        },
        async loadAuditPaymentList(aud_req_id) {
            this.paymentLoader = true;
            this.auditPaymentList = [];
            const response = await API.get(
                `api/projectbid/get-by-iform-id?iform_id=${aud_req_id}`
            );
            this.paymentLoader = false;
            if (response.status == 200) {
                this.auditPaymentList = response.data;
            }
        },
        async auditPaymentConfirmation(data) {
            const response = await API.post("api/audit/pay-confirm", data);
            if (response.status == 200) {
                useToast("Status Changed Successfully", "success");
            } else {
                useToast(response);
            }
        },
        async loadAuditControlsList(comp_id, path = "Compliance-View") {

            this.controlLoader = true;
            const response = await API.get(`api/audit/${path}?aud_req_id=${comp_id}`);
            if (response.status == 200) {
                this.auditControlsList = response.data;
                this.complianceList = response.data.compliance_audited
                this.controlLoader = false;
                this.auditStartWork = response.data.auditStartWork;
                if (path == "Control-by-id") {
                    this.justifications = setJustifications(response.data.compliance_audited)
                }
            } else {
                useToast(response);
            }
            return response;
        },
        async sendAuditControls(data) {
            this.controlLoader = true;
            axios.defaults.headers.common["x-access-token"] =
                localStorage.getItem("user_access_token");
            const response = await axios.post(
                `${apiBaseURL}/api/audit/send-complianceAudited`,
                data
            );
            if (response.status != 200) {
                useToast(response);
            }
            this.controlLoader = false;
            return response;
        },
        async updateAuditControl(currentCompliance) {
            this.controlLoader = true;
            const response = await API.post(`api/audit/complianceAudited/update`, currentCompliance);
            if (response.status != 200) {
                useToast(response);
            }
            this.controlLoader = false;
            return response;
        },
        async updateJustification(justifications, aud_req_id) {
            console.log(aud_req_id);
            this.controlLoader = true;
            const response = await API.post(`api/audit/complianceAudited/justification`, { justifications, aud_req_id });
            if (response.status != 200) {
                useToast(response);
            }
            this.controlLoader = false;
            return response;
        },
        async sendAuditSupportEvidence(data) {
            axios.defaults.headers.common["x-access-token"] =
                localStorage.getItem("user_access_token");
            const response = await axios.post(
                `${apiBaseURL}/api/audit/send-support-evidence`,
                data
            );
            if (response.status != 200) {
                useToast(response);
            }
            return response;
        },
        async deleteAuditSupportEvidence({ aud_req_id, doc_path }) {
            axios.defaults.headers.common["x-access-token"] = localStorage.getItem("user_access_token");
            const response = await axios.delete(
                `${apiBaseURL}/api/audit/delete-support-evidence`,
                {
                    data: { aud_req_id, doc_path },
                }
            );
            if (response.status != 200) {
                throw Error(response.response);
            }
            // after delete remove from document list

            return response;
        },
        async confirmJustification({ compliance_audited_id }) {
            axios.defaults.headers.common["x-access-token"] = localStorage.getItem("user_access_token");
            const response = await axios.post(
                `${apiBaseURL}/api/audit/complianceAudited/confirm-justification`,
                {
                    data: { compliance_audited_id },
                }
            );
            if (response.status != 200) {
                throw Error(response.response);
            }

            return response;
        },
        async sendAuditAskQuestion(data) {
            this.btnLoader = true;
            const response = await API.post("api/audit/aud-sup-conversation", data);
            this.btnLoader = false;
            if (response.status != 200) {
                useToast(response);
            }
            return response;
        },
        async sendAuditAskDocuments(data) {
            this.btnLoader = true;
            const response = await API.formData("api/audit/auditor-doc-req", data);
            this.btnLoader = false;
            if (response.status == 200) {
                useToast(response.data, "success");
            } else {
                useToast(response);
            }
            return response;
        },
        async updateStatusCertificateNames(data, silent = false) {
            const response = await API.post("api/audit/audit-doc", data);
            if (response.status == 200) {
                const data = [response.data];
                this.auditCertificatesName = data;

                if (false === silent) useToast("Status Changed", "success");
            } else {
                if (false === silent) useToast(response);
            }

            return response;
        },
        async loadAuditAskQuestion(id) {
            const response = await API.get(
                `api/audit/get-aud-supplier-conversation?audit_request_id=${id}`
            );
            if (response.status == 200) {
                this.auditMessages = response.data;
            } else {
                useToast(response);
            }
        },
        async loadCertificateName(data) {
            const response = await API.get("api/audit/certificate-name", data);
            if (response.status == 200) {
                this.auditCertificatesName = response.data;
            } else {
                useToast(response);
            }
        },
        async loadAuditDocumentList(id) {
            const response = await API.get(
                `api/audit/get-aud-doc?audit_req_id=${id}`
            );
            if (response.status == 200) {
                this.auditDocuments = response.data;
            } else {
                useToast(response);
            }
            return response;
        },
        async loadSingleCertificate(id) {
            this.singleCertificateLoader = true;
            const response = await API.get(
                `api/offercertificate/get-by-id?Offering_id=${id}`
            );
            this.singleCertificateLoader = false;
            if (response.status == 200) {
                this.auditSingleCertificate = response.data;
            } else {
                useToast(response);
            }
        },
        async loadDocumentStatus(id) {
            const response = await API.get(
                `api/audit/get-doc-status?audit_req_id=${id}`
            );
            if (response.status == 200) {
                this.supDocumentStatus = response.data;
            } else {
                useToast(response);
            }
        },
        async loadRegulatorVerifyCertificate(id) {
            this.loader = true;
            const response = await API.get(
                `api/audit/get-verify-certificate?regulator_by_regd_id=${id}`
            );
            this.loader = false;
            if (response.status == 200) {
                this.regVerifyCertificates = response.data;
            } else {
                useToast(response);
            }
        },
        async loadAuditReport(id, isDraft = false) {
            this.loader = true;
            const response = await API.get(`api/audit/get-report?report_id=${id}&isDraft=${isDraft}`);
            this.loader = false;
            if (response.status == 200) {
                this.auditReport = response.data;
                localStorage.setItem("report", JSON.stringify(response.data));
            } else {
                useToast(response);
            }
            return response;
        },
        async loadCertificateReport(url, aud_id) {
            this.loader = true;
            const response = await API.get(`${url + aud_id}`);
            this.loader = false;
            if (response.status == 200) {
                this.Certificate = response.data;
            } else {
                useToast(response);
            }
            return response;
        },

        async loadAuditReportList(payload) {
            this.loader = true;
            const response = await API.get(`api/general/audited_report`, payload);
            this.loader = false;
            if (response.status == 200) {
                this.totalPages = Math.ceil(response.data.total / payload.RowNo);
                this.auditRptList = response.data.audited_report;
            }
            return response;
        },

        async loadCertificateTemplateView(payload) {
            this.loader = true;
            const response = await API.get(`api/certificate/certificateTemplateView?RegD_id=${payload.RegD_id}&Certificate_id=${payload.Certificate_id}`);
            this.loader = false;
            return response;
        },

        async loadNotAuditPaymentList(payload) {
            this.loader = true;
            const response = await API.get(
                `api/audit/Not-Audited-rpt?is_admin=false`,
                payload
            );
            this.loader = false;
            if (response.status == 200) {
                this.totalPages = Math.ceil(response.data.total / payload.RowNo);
                this.NotauditList = response.data.list;
            }
            return response;
        },

        async loadInProcessAudit(payload) {
            this.loader = true;
            const response = await API.get(
                `api/general/in_process_audited_report`,
                payload
            );
            this.loader = false;
            if (response.status == 200) {
                this.totalPages = Math.ceil(response.data.total / payload.RowNo);
                this.inProcessAudit = response.data.in_process_audited_report;
            }
            return response;
        },
        async loadReAudit(payload) {
            this.loader = true;
            const response = await API.get(`api/general/re_audited_report`, payload);
            this.loader = false;
            if (response.status == 200) {
                this.totalPages = Math.ceil(response.data.total / payload.RowNo);
                this.reAudit = response.data.re_audited_report;
            }
            return response;
        },
    },

});
