import { defineStore } from "pinia";
import Api from '@/APi/axios_instance'
import showToast from '@/global_components/Toast/useToast';
export const Account = defineStore({
    id: 'account',
    state: () => ({
        Account_Id: '',
        Account_detail: JSON.parse(localStorage.getItem('switch_account'))||'',
        User_role:'',
        dashboard_data:{}
    }),
    actions:{
     async getDashboardData(){
        try{
            const resp = await Api.get(`/api/general/dashboardData?RegD_id=${this.Account_detail[0].RegD_id}&Account_id=${this.Account_detail[0].Account_type}`)
            this.dashboard_data = resp.data
        }catch(error){
           if(error.response)
           {
            showToast(error.response.data)
           }else{
            showToast('internet Error / Server Not Respound')
           }
        }
     }
   }

})